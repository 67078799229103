import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dealsService from "../../services/dealsService";
import { useSessionStorage } from "../../components/storage/sessionStorage";
import usersService from "../../services/usersService";


export interface IDeal {
    url: string;
    id: string
    eysImageUrl: string;
    callToAction: string;
    shortDescription: string;
    friendlyUrl: string;
    name: string;
    instructions: string[]
}

export interface DealsContextType {
    dealsAreLoading: boolean
    setDealsAreLoading: React.Dispatch<React.SetStateAction<boolean>>
    deals?: IDeal[]
    level?: string
    setLevel: React.Dispatch<React.SetStateAction<"bronze" | "silver" | "gold" | undefined>>;
    dealsRequired: string
    dealStatus: IDealStatus | null
}

interface IDealStatus {
    levels: IDealStatusLevel[];
}

interface IDealStatusLevel {
    level: string;
    tasks: IDealSummary[]
}

interface IDealSummary {
    id: string;
    status: string;
    level: string;
    name: string;
}

const DealsContext = createContext<DealsContextType | undefined>(undefined);

const DealsContextProvider = ({ children }: any) => {
    const navigate = useNavigate()
    const [token, setToken] = useSessionStorage("token", undefined)
    const [dealsAreLoading, setDealsAreLoading] = useState(false)
    const [deals, setDeals] = useState<IDeal[]>([])
    const [level, setLevel] = useState<"bronze" | "silver" | "gold">()
    const [dealsRequired, setDealsRequired] = useState("1 deal")
    const [dealStatus, setDealStatus] = useState<IDealStatus | null>(null);
    const [fetchCount, setFetchCount] = useState(0)

    useEffect(() => {
        if (level) {
            setDealsAreLoading(true)
            // get deals for level, for user.
            dealsService.getDeals(level)
                .then((r: any) => {
                    setDeals(r.data)
                    setDealsAreLoading(false)
                })

            let dealsRequired = "1 deal"

            switch (level) {
                case "gold":
                    dealsRequired = "5 deals"
                    break
                case "silver":
                    dealsRequired = "2 deals";
            }

            setDealsRequired(dealsRequired)
        }
    }, [level]);

    useEffect(() => {
       const ab = new AbortController()

        usersService.getUserStatus(ab.signal)
            .then(res => {
                setDealStatus(res.data);
            })
            .catch(ex => {
                console.log(ex)
            })

            return () => ab.abort()
    }, [fetchCount])

    useEffect(()=> {
        const id = setTimeout(() => {
            if(fetchCount < 180) {
                setFetchCount(fetchCount + 1)
            }
        }, 10000)

        return () => clearTimeout(id)
    })

    useEffect(() => {
        if (!token || token == null) {
            navigate("/")
        }
    }, [])

    /* const alertMessageDispatch = (message: string, type: alertMessageType, dispatch: any) => {
       dispatch(
         alertMessageAction({
           message: message,
           type: type,
           show: true
         })
       );
     }*/

    const contextValue = {
        deals,
        level,
        setLevel,
        dealsAreLoading,
        setDealsAreLoading,
        dealsRequired,
        dealStatus
    }

    return (
        <DealsContext.Provider value={contextValue}>
            {children}
        </DealsContext.Provider>
    );
};

export { DealsContext, DealsContextProvider };
