
import { green,blue,grey } from '@mui/material/colors';

export const theme = {
    palette: {
      primary: {
        main: grey[100],
      },
      secondary: {
        main: green[500],
      },
    },
  }

  export default theme