import { Container, styled } from "@mui/material"

export const SiteContainer = styled(Container)`
   margin: 1rem .5rem;
   font-size: 19px;

   @media(max-width: 500px) {
      margin-left: 0;
      margin-right: 0;
   }
`