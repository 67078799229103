import { CircularProgress, Link, Modal } from "@mui/material"
import { ModalContent } from "../pages/deals/styles"
import { SiteContext } from "./site-context"
import { useContext } from "react"

export const SiteModal = () => {
    const { showModal, modalContent, setShowModal, modalContentLoading } = useContext(SiteContext)!
    
    return <Modal open={showModal}>
        <ModalContent>
            {!modalContentLoading && typeof modalContent !== "object" && <Link style={{ cursor: "pointer", color: "#333", position: "absolute", top: "16px", right: "16px", fontSize: ".8rem" }} onClick={() => { setShowModal(false) }}>X</Link>}
            {modalContentLoading && <div style={{ textAlign: "center" }}> <CircularProgress /></div>}
            {!modalContentLoading && typeof modalContent !== "object" &&  <span style={{ textAlign: "left", display: "block", marginTop: "-4rem" }} dangerouslySetInnerHTML={{ __html: modalContent! }}></span>}
            {!modalContentLoading && typeof modalContent === "object" &&  <span style={{ textAlign: "left", display: "block" }}>{modalContent}</span>}
            {!modalContentLoading && typeof modalContent !== "object" && <Link style={{ cursor: "pointer", color: "#333" }} onClick={() => { setShowModal(false) }}>Close</Link>}
        </ModalContent>
    </Modal>
}