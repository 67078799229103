import { useState } from "react";

export const useSessionStorage = (keyName: string, defaultValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.sessionStorage.getItem(keyName);
     
      if (value && typeof value === "object") {
        return JSON.parse(value);
      }
      else if(value && typeof value !== "object") {
        return value;
      }
      else {
        if(value) {
          window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue));
        }
        return defaultValue;
      }
    } catch (err) {
      console.log(err)
      return defaultValue;
    }
  });
  
  const setValue = (newValue: any) => {
    try {
      if(typeof newValue === "object") {
        window.sessionStorage.setItem(keyName, JSON.stringify(newValue));
      }
      else 
        window.sessionStorage.setItem(keyName, newValue);
    } catch (err) {}
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};