import React, { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import Layout from "../layout/";
import { DealsContextProvider } from "../pages/deals/context";

const Home = lazy(() => import("../pages/home/"));
const Document = lazy(() => import("../pages/documents/"));
const Deals = lazy(() => import("../pages/deals/"));
const CheckDealStatus = lazy(() => import("../pages/deals/check-status"));

const useRenderRoutes = () =>
  useRoutes([
    {
      element: (
        <Layout />
      ),
      children: [
        { index: true, element: <Home /> },
        {
          path: "deals/bronze",
          element: (
            <Deals level={"bronze"} />
          ),
        },
        {
          path: "deals/silver",
          element: (
            <Deals level={"silver"} />
          ),
        },
        {
          path: "deals/gold",
          element: (
            <Deals level={"gold"} />
          ),
        },
        {
          path: "deals/check-status",
          element: (
            <DealsContextProvider>
              <CheckDealStatus />
              </DealsContextProvider>
          )
        },
        {
          path: "documents/:documentType",
          element: (
            <Document />
          ),
        }]
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);

const renderRoutes = { useRenderRoutes };

export default renderRoutes;