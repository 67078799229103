import { createContext, useEffect, useState } from "react";
import {  useSearchParams } from "react-router-dom";
import rewardsService from "../services/rewardsService";
import { Box, CircularProgress } from "@mui/material";
import { SiteModal } from "./modal";
import { useSessionStorage } from "../components/storage/sessionStorage";

export interface IReward {
    id: string
    name: string;
    value: string;
    imageUrl: string;
    imageSmallUrl: string;
}

export interface SiteContextType {
    reward: IReward | null;
    rewardId: string;
    siteLoading: boolean;
    showModal: boolean;
    modalContent: string | null;
    modalContentLoading: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setModalContentLoading: React.Dispatch<React.SetStateAction<boolean>>
    setModalContent: React.Dispatch<React.SetStateAction<any>>
}

const SiteContext = createContext<SiteContextType | undefined>(undefined);

const SiteContextProvider = ({ children }: any) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [rewardId, setRewardId] = useSessionStorage("rewardId", undefined)
    const [reward, setReward] = useState<IReward | null>(null)
    const [siteLoading, setSiteLoading] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [modalContentLoading, setModalContentLoading] = useState(false)
    const [modalContent, setModalContent] = useState<string | null>(null)
    
    useEffect(() => {
        const id = searchParams.get("r");

        if (id !== null && id != rewardId) {
            setSiteLoading(true)
            rewardsService.getReward(id)
                .then(res => {
                    if(res.status === 200) {
                        setReward(res.data)
                        setRewardId(res.data.rewardId)
                        setSiteLoading(false)
                    }
                })
        }
        else if (rewardId === undefined && id === null) {
            setSiteLoading(true)
            rewardsService.getDefaultReward(id!)
                .then(res => {
                    if(res.status === 200) {
                        setReward(res.data)
                        setRewardId(res.data.rewardId)
                        setSiteLoading(false)
                    }
                })
        }
        else if (rewardId && reward === null) {
            setSiteLoading(true)

            rewardsService.getReward(rewardId)
                .then(res => {
                    if(res.status === 200) {
                        setReward(res.data)
                        setRewardId(res.data.rewardId)
                        setSiteLoading(false)
                    }
                })
        }

    }, [searchParams]);

    /* const alertMessageDispatch = (message: string, type: alertMessageType, dispatch: any) => {
       dispatch(
         alertMessageAction({
           message: message,
           type: type,
           show: true
         })
       );
     }*/

    const contextValue = {
        reward,
        rewardId,
        siteLoading,
        showModal, setShowModal,
        modalContent, setModalContent,
        modalContentLoading, setModalContentLoading
    }

    return (
        <SiteContext.Provider value={contextValue}>
            {siteLoading && <Box style={{ textAlign: "center", marginTop: "10%" }}><CircularProgress /></Box>}
            {!siteLoading && children}
            <SiteModal />
        </SiteContext.Provider>
    );
};

export { SiteContext, SiteContextProvider };
