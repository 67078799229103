import { Box, Button, styled } from "@mui/material";

export const DealButton = styled(Button)`
    background: rgb(193, 39, 45);
    font-size: 1rem;
    font-weight: 700;
    margin: 1rem auto;
    color: #fff;

    &:hover {
        background: rgb(193, 39, 45);
        opacity: .9;
    }

    @media(max-width: 500px) {
        span {
            display: none;
        }
    }

`

export const BackToDealsButton = styled(DealButton)`
    background: #fff;
    border: solid 1px rgb(193, 39, 45);
    color: rgb(193, 39, 45);

       &:hover {
        background: #fff;
        opacity: .9;
    }

    
`

export const DealButtonLink = styled("a")`
    background: rgb(193, 39, 45);
    font-size: 1rem;
    font-weight: 700;
    margin: 1rem auto;
    color: #fff;
    padding: .75rem 2rem;
    text-decoration: none;
    border-radius: 5px;

    &:hover {
        background: rgb(193, 39, 45);
        opacity: .9;
    }
`

export const ModalContent = styled(Box)`
    width: 500px;
    position: relative;
    padding: 2rem;
    background: #fff;
    border-radius: 10px;
    text-align: center;
    margin: 5% auto;
    max-height: 80%;
    overflow-y: scroll;

    span {
        font-weight: 700;
    }

    button {
        margin: 0 1rem;
    }

    @media (max-width: 520px) {
        width: calc(100% - 30px);
    }
`
