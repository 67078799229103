import { Box, CircularProgress } from "@mui/material"

export const PageLoading = () => {

    return <Box style={{
            height: "100vh",
            background: "#145ec7",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw"
          }}>
            <CircularProgress />
         </Box>
}