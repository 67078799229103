import Footer from "./footer";
import Header from "./header";
import { Outlet } from 'react-router-dom';
import { SiteContainer } from './styles';
import CssBaseline from "@mui/material/CssBaseline";
import { SiteContextProvider } from "./site-context";

export const Layout = () => <>
    <CssBaseline />
    <SiteContextProvider>
    <SiteContainer disableGutters maxWidth={false}>
        <Header />
        <Outlet />
        <Footer />
    </SiteContainer>
    </SiteContextProvider>
    </>;

export default Layout