
import {
    GET_ALL_DISPLAY_OFFERS,
    TRACK_DISPLAY_OFFER_CLICK
} from "./CONSTANTS";
import http from "../http-common";

class DealsService {
    getDeals(level: string) {
        return http
            .get(GET_ALL_DISPLAY_OFFERS(level))
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    }
    track(url: string, category: string) {
        return http.post(
            TRACK_DISPLAY_OFFER_CLICK(url),
            { category: category, visitId: sessionStorage.getItem("visitIdentifier") },
            {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
            }
        );
    }
}

export default new DealsService()