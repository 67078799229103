import { Box, Container, styled } from "@mui/material";
import React from "react";

export const Footer = () => <FooterContainer maxWidth={false} disableGutters={true}>

    <FooterParagraph>It's free to join Earn Your Swag. Some third party promotions may require you to make a payment or feature premium rate entry.

        Earn Your Swag is an independent rewards program for consumers and is not affiliated with, sponsored by or endorsed by any of the listed products or retailers. Trademarks, logos, service marks, and/or domain names (including, without limitation, the individual names of products and retailers) are the property of their respective owners.

        By registering and entering your details you agree to the Privacy Policy, Terms & Conditions.</FooterParagraph>
    <FooterLinks>
        <a target="_blank" href={"documents/terms-and-conditions"}>Terms and conditions</a>
        <a target="_blank" href={"documents/privacy-policy"}>Privacy Policy</a>
        <a target="_blank" href={"documents/about"}>Participation requirements</a>
    </FooterLinks>
    <p style={{ fontSize: ".8rem", textAlign: "center" }}>&copy; Earn Your Swag {new Date().getFullYear()}</p>
</FooterContainer>

const FooterLinks = styled(Box)`
    text-align: center;
    font-size: .75rem;
    
    a { margin-left: 2rem;
    color: #333;
    
     }
`

const FooterParagraph = styled("p")`
    font-size: .75rem;
`

const FooterContainer = styled(Container)`
    margin-top: 3rem;
    background: #eee;
    padding: 2rem 2rem 5rem;
    `

export default Footer