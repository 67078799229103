// All user related database operations can be defined here.

import {
  CREATE_USER,
  GET_USER,
  AUTO_LOGIN_USER,
  SET_EMAIL_SUBSCRIPTION,
  GET_USER_STATUS
} from "./CONSTANTS";
import http from "../http-common";

export interface LoginModel {
  username: string;
  password: string;
  websiteId: string;
}

export interface AutoLoginModel {
  userId: string;
  autoLoginCode: string;
  websiteId: string;
}

export interface SubscriptionRequestModel {
  optin: boolean;
}

class UsersDataService {
  createUser(user: any) {
    return http
      .post(CREATE_USER, user)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  getUserStatus(signal: any) {
    return http.get(GET_USER_STATUS, { 
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
      signal
  });
  }

  getUser(user: any) {
    return http.get(GET_USER, {
      headers: {
        Authorization: user.type + " " + user.token,
      },
    });
  }

  autoLoginUser(loginRequest: AutoLoginModel) {
    return http.post(AUTO_LOGIN_USER, loginRequest);
  }

  setUserSubscription(
    user: any,
    emailSubscriptionRequest: SubscriptionRequestModel
  ) {
    return http.post(SET_EMAIL_SUBSCRIPTION, emailSubscriptionRequest, {
        headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
        }
    });
  }
}

export default new UsersDataService();
