import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useSessionStorage } from "../components/storage/sessionStorage";
import websiteVisitService from "../services/websiteVisitService";

const WebsiteVisitTracking = () => {
  const [searchParams] = useSearchParams();
  const [visitId, setVisitId] = useSessionStorage("visitIdentifier", undefined);
  const visitTracked = useRef(false);

  useEffect(() => {
    if (visitTracked.current || (visitId !== undefined && visitId !== null))
      return;

    visitTracked.current = true;

    websiteVisitService.trackVisit({
      affiliateId: searchParams.get("utm_source") || "DIRECT",
      affiliateSubId: searchParams.get("aff_sub") || "",
      platformTransactionId: searchParams.get("aff_sub2") || "",
      affiliateTransactionId: searchParams.get("aff_sub3") || "",
      websiteId: "C3DA385D-7959-469E-9BC3-3B15273402BF",
      tag1: searchParams.get("t1"),
      tag2: searchParams.get("t2"),
      tag3: searchParams.get("t3"),
      tag4: searchParams.get("t4"),
      tag5: searchParams.get("t5"),
    }).then((res: any) => {
        setVisitId(res.data.visitId)
    });
  }, [searchParams]);

  return <></>;
};

export default WebsiteVisitTracking;
