import { ErrorBoundary } from "react-error-boundary";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Routes from "./router";
import WebsiteVisitTracking from "./layout/website-visit-tracking";
import { CssBaseline } from "@mui/material";
import { Suspense } from "react";
import { PageLoading } from "./layout/page-loading";

const logError : any = (error: Error, info: { componentStack: string }) => {
  console.log(error)
};

function App() {
  return (
    <Suspense fallback={<PageLoading />}>
      <ErrorBoundary
        fallback={<div>Something went wrong</div>}
        onError={logError}
      >
         <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          {Routes.useRenderRoutes()}
          <WebsiteVisitTracking />
        </LocalizationProvider>
      </ErrorBoundary>
      </Suspense>
  );
}

export default App;
